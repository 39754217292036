<!-- 第一页 -->
<template>
  <div style="width:22.45rem;height:10.6625rem;overflow: hidden;">
    <!-- 左边 -->
    <div
      v-if="schoolType == 1"
      class="tabs"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.9)"
    >
      <div
        :class="tabsNum===index?'active tab-item':'tab-item'"
        v-for="(item,index) in tabsData"
        :key="index"
        @click="changeTab(index)"
      >
        <!-- tab-item -->
        <!-- icon -->
        <div class="iconBox" v-if="activeName != 'video'">
          <img
            :src="tabsNum===index?w_topIcon:b_topIcon"
            alt=""
            style="width:100%;height:100%"
            v-show="item.icon_type === 1"
          >
          <img
            :src="tabsNum===index?w_centerIcon:b_centerIcon"
            alt=""
            style="width:100%;height:100%"
            v-show="item.icon_type === 2"
          >
          <img
            :src="tabsNum===index?w_bottomIcon:b_bottomIcon"
            alt=""
            style="width:100%;height:100%"
            v-show="item.icon_type === 3"
          >
          <!-- <img :src="tabsNum===index?w_centerIcon:b_centerIcon" alt="" v-if="index === 1" style="width:100%;height:100%"> -->
          <!-- <img :src="tabsNum===index?w_bottomIcon:b_bottomIcon" alt="" v-if="index === 2" style="width:100%;height:100%"> -->
        </div>
        <!-- icon title -->
        <!-- <div class="gradeName">{{item.icon_title}}</div>{{item.title}} -->
        <!-- 年龄段 -->
        <div class="gradeName">
          <div class="gradeNameItem">{{item.icon_title}}</div>
          <div class="gradeNameItem">{{item.title}}</div>
        </div>
      </div>
    </div>
    <!-- 左边 -->
    <div
      v-if="schoolType == 2"
      class="tabs"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.9)"
    >
      <div
        :class="tabsNum===index?'active tab-item':'tab-item'"
        v-for="(item,index) in tabsData"
        :key="index"
        @click="changeTab(index)"
      >
        <!-- tab-item -->
        <!-- icon -->
        <div class="iconBox">
          <img
            :src="tabsNum===index?w_topIcon_m:b_topIcon_m"
            alt=""
            style="width:100%;height:100%"
            v-show="item.icon_type === 1"
          >
          <img
            :src="tabsNum===index?w_centerIcon:b_centerIcon"
            alt=""
            style="width:100%;height:100%"
            v-show="item.icon_type === 2"
          >
          <img
            :src="tabsNum===index?w_bottomIcon:b_bottomIcon"
            alt=""
            style="width:100%;height:100%"
            v-show="item.icon_type === 3"
          >
          <!-- <img :src="tabsNum===index?w_centerIcon:b_centerIcon" alt="" v-if="index === 1" style="width:100%;height:100%"> -->
          <!-- <img :src="tabsNum===index?w_bottomIcon:b_bottomIcon" alt="" v-if="index === 2" style="width:100%;height:100%"> -->
        </div>
        <!-- icon title -->
        <!-- <div class="gradeName">{{item.icon_title}}</div> -->
        <!-- 年龄段 -->
        <!-- <div class="gradeName">{{item.title}}</div> -->
        <div class="gradeName">
          <div class="gradeNameItem">{{item.icon_title}}</div>
          <div class="gradeNameItem">{{item.title}}</div>
        </div>
      </div>
    </div>
    <!-- 右边 -->
    <el-tabs
      type="border-card"
      class="courseVideo"
      v-loading="loading"
      v-model="activeName"
      @tab-click="tabPane"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.9)"
    >
      <el-tab-pane label="课程视频" name="video">
        <div class="videoBox">
          <!-- <video-player  class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
              style="width:100%;height:100%"
              v-show="false"
          ></video-player> -->
          <VueAliplayer
            @play="played"
            :source="aplayer.source"
            :vid="aplayer.vid"
            :playauth="aplayer.playauth"
            ref="player"
            v-if="isShow"
            style="width:100%;height:100%"
            :cover="aplayer.cover"
            :controlBarVisibility="aplayer.controlBarVisibility"
          >
          </VueAliplayer>
        </div>
      </el-tab-pane>
      <el-tab-pane label="上课工具" name="tool">
        <div class="courseTool" id="classTool">
          <div
            v-for="(item,index) in imgList"
            :key="index"
            id="classToolDiv"
          >
            <img
              :src="item.path"
              alt=""
              style="width:100%"
              v-if="isImg"
            >
            <!--            <pdf  v-for="i in pdfNumPages" :key="i"  :src="pdfSrc" :page="i" v-else></pdf>-->
            <!-- <div v-if="pdfLoadedRatio > 0 && pdfLoadedRatio < 1" style="background-color: green; color: white; text-align: center" :style="{ width: pdfLoadedRatio * 100 + '%' }">{{ Math.floor(pdfLoadedRatio * 100) }}%</div>
            <div v-if="loadingFlag" style="background-color: green; color: white; text-align: center" >loading</div> -->
            <pdf v-show="pdfSrc" ref="pdf" style="border: 1px solid red" :src="pdfSrc" v-for="pageNum in pdfNumPages" :page="pageNum" :key="pageNum" @page-loaded="pdfPageLoading"  @progress="pdfLoadedRatio = $event" @error="pdfError"></pdf>
          </div>
		  <div v-if="pdfLoadedRatio > 0 && pdfLoadedRatio < 1 && loadingFlag && tabsNum === 1" class="courseTool loadingbox">
			<div class='loadingContent'>
				<el-progress type="circle" :percentage="pdfLoadedRatio.toFixed(2) * 100"></el-progress>
				<div class="loadingText">文件较大,加载中请稍后....</div>
			</div>
		  </div>
		</div>
      </el-tab-pane>
      <el-tab-pane label="素材" name="material" v-if="schoolType == 1">
        <div class="courseTool">
          <div class="material" v-for="item in materialData" :key="item.id">
            <div class="material-item">
              <!-- <viewer :images="arr">
                <img :src="item.path" alt="" style="width:100%;cursor:pointer;position: absolute;top:50%;transform: translateY(-50%);left:0;">
              </viewer> -->
              <el-image :src="item.path" :preview-src-list="arr"  style="width:100%;cursor:pointer;position: absolute;top:50%;transform: translateY(-50%);left:0;">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div class="img-title">{{item.title}}</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script type="text/ecmascript-6">
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import apis from '../../../api/request'
// import { videoPlayer } from 'vue-video-player'
// import 'video.js/dist/video-js.css'
import tool from '../../../../static/images/text.png'
// import icon1 from '../../../../static/images/b-download.png'
import icon2 from '../../../../static/images/b-lamp.png'
import icon3 from '../../../../static/images/b-z.png'
// import icon4 from '../../../../static/images/w-download.png'
import icon5 from '../../../../static/images/w-lamp.png'
import icon6 from '../../../../static/images/w-z.png'
import icon7 from '../../../../static/images/b-plane.png'
import icon8 from '../../../../static/images/w-plane.png'
import VueAliplayer from "../../components/AliPlayer.vue";
import pdf from 'vue-pdf'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    VueAliplayer,
    pdf
  },
  data () {
    //这里存放数据
    return {
      arr:[],

      materialList:[],
      videoTabsData:[],
      activeName:'video',
      pdfSrc: '',
      pdfLoadedRatio: 0,
      pdfShow: 0,
      pdfPage: 1,
      pdfNumPages: 1,
      pageLoadNum: 1,
      loadingFlag: true,
      toolData:[
        {
          id:1,
          icon_type:1,
          title:'作品呈现'
        },
        {
          id:2,
          icon_type:2,
          title:'教案'
        },
        {
          id:3,
          icon_type:3,
          title:'操作手册'
        }
      ],
      smalltoolData:[
        {
          id:2,
          icon_type:1,
          title:'教案'
        }
      ],
      chapter_video_id:'',
      materialData:[],//素材数据
      isImg:true,
      schoolType:"",

      loading:false,
      imgList: [],
      isShow: false,
      video: {},
      chapter_id: "",
      textPic: tool,
      b_topIcon: icon7,
      w_topIcon: icon8,
      b_topIcon_m: icon7,
      w_topIcon_m: icon8,
      b_centerIcon: icon2,
      w_centerIcon: icon5,
      b_bottomIcon: icon3,
      w_bottomIcon: icon6,
      tabsNum: 0,
      tabsData: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          src: require('../../../../static/video/text.mp4'),  // 路径
          type: 'video/mp4'  // 类型
        }],
        poster: require('../../../../static/images/art.png'), //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,//当前时间和持续时间的分隔符
          durationDisplay: true,//显示持续时间
          remainingTimeDisplay: false,//是否显示剩余时间功能
          fullscreenToggle: true,  //全屏按钮
        }
      },
      aplayer: {
        source: "",
        vid: "",
        playauth: "",
        cover: "",
        controlBarVisibility: "hover"
      },
    };
  },
  //监听属性 类似于data概念
  computed: {

  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pdfError: function(err) {
      console.log(err);
    },
    pdfPageLoading(event){
      this.pageLoadNum++;
      if (this.pageLoadNum <= this.pdfNumPages) {
        this.loadingFlag = false;
        console.log(this.pageLoadNum, this.pdfNumPages, "pageLoaded");
      }else{
        console.log(this.pageLoadNum, this.pdfNumPages, "pageLoading");
      }
    },
    tabPane(tab){
      if(tab.name == 'tool'){
        // console.log('279', this.schoolType);
        // return
        if(this.schoolType == 2){
          this.tabsData = this.smalltoolData
        }
        if(this.schoolType == 1){
          this.tabsData = this.toolData
        }
        this.changeTab(0)
      }
      if(tab.name == 'video'){
        this.tabsData = this.videoTabsData
      }
      if(tab.name == 'material'){
        this.tabsData = this.materialList
        if(this.tabsData&&this.tabsData.length!=0){
          this.changeTab(0)
        }else{
          this.$message.error("该章节没有素材哦")
        }
        // console.log('290', this.tabsData[0].id);
      }

    },
    getNumPages(pdfUrl) {
      var loadingTask = pdf.createLoadingTask(pdfUrl)
      loadingTask.promise.then(pdfData => {
        this.pdfSrc = loadingTask
        this.pdfNumPages = pdfData.numPages
        // document.getElementById("classTool").scrollTop = 0;
        // console.log('214', document.getElementById("classTool"))
      }).catch((err) => {
        console.log(err);
        console.error('pdf加载失败')
      })
    },
    changeTab (index) {
      this.loading = true
      if(this.activeName == 'video'){
        this.isShow = false
        // console.log(index);
        localStorage.setItem("videoId", this.tabsData[index].id)
        this.tabsNum = index
        this.chapter_id = localStorage.getItem("videoId")
        this.$get(apis.videoInfo + this.chapter_id, { video_id: this.chapter_id }).then(res => {
          // console.log(res);
          if (res.code == 0) {
            this.video = res.data.video
            this.aplayer.vid = res.data.video.aliyun_video_id
            this.aplayer.cover = res.data.video.cover
            if (res.data.video.aliyun_video_id !== "") {
              this.aplayer.source = ""
              this.$get(apis.playAuth, { video_id: this.aplayer.vid }).then(res => {
                // console.log(res);
                if (res.code == 0) {
                  this.aplayer.playauth = res.data.playAuth
                  // console.log(this.aplayer.playAuth );
                  this.isShow = true
                }
              })
            }
            if (res.data.video.url !== "") {
              this.aplayer.cover = ""
              this.aplayer.playauth = ""
              this.aplayer.source = res.data.video.url
              this.isShow = true
            }
            this.loading = false
          }
        })
      }
      if(this.activeName == 'tool'){
        this.tabsNum = index
        this.$get(apis.videoTool, { course_id: this.course_id , type:this.tabsData[index].id, chapter_id:this.chapter_video_id || 0}).then(res => {
          if (res.code === 0) {
            this.imgList = res.data.data

            document.getElementById("classTool").scrollTop = 0;
            if(this.imgList[0] != undefined && this.imgList[0].extension == "pdf"){
              this.isImg =false
              this.pdf_url = this.imgList[0].path
              // document.getElementById("classTool").scrollTop = 0;
              this.getNumPages(this.pdf_url)
              this.loading = false
            }else{
              this.isImg =true
              this.loading = false
            }
          }
          console.log('pdfSrc', this.pdfSrc)
        })
      }
      if(this.activeName == 'material'){
        this.tabsNum = index
        let categoryId = this.tabsData[index].id
        this.$get(apis.getmaterial + this.course_id, { course_id: this.course_id, category_id: categoryId}).then(res => {
          this.loading = false
          // console.log(res.data.list);
          if(res.code == 0){
            this.materialData = res.data.list
            for(let i =0 ; i < res.data.list.length ; i++){
              this.arr.push(res.data.list[i].path)
            }
            // console.log('390', this.arr);
          }
        })
      }
    },
    played () {

    }
  },
  beforeCreate () { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  beforeMount () { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.loading = true
    this.schoolType = localStorage.getItem("schoolType")
	console.log('304', this.schoolType)
    this.course_id = localStorage.getItem("courseId")
    this.chapter_video_id = localStorage.getItem("chapterId")
    this.$get(apis.videoList, { course_id: this.course_id , chapter_id:this.chapter_video_id}).then(res => {
      console.log(res);
      if (res.code === 0) {
        if(res.data.data == ""){
          this.$message.error("当前并没有可播放的视频")
          this.loading = false
          return
        }
        this.tabsData = res.data.data
        this.videoTabsData = res.data.data
        // for (let i = 0; i < this.tabsData.length; i++) {
        //   if (this.tabsData[i].icon_type === 1) {//要改
        //     this.b_topIcon = icon1
        //     this.w_topIcon = icon4
        //   }
        //   if (this.tabsData[i].icon_type === 2) {
        //     this.b_topIcon = icon2
        //     this.w_topIcon = icon5
        //   }
        //   if (this.tabsData[i].icon_type === 3) {
        //     this.b_topIcon = icon3
        //     this.w_topIcon = icon6
        //   }
        // }
        this.changeTab(0)
      }
    })
    this.$get(apis.getmaterialCategory + this.course_id, {course_id: this.course_id}).then(res=>{
      console.log('432',res);
      if(res.code == 0){
        this.materialList = res.data
        for(let i = 0 ; i < this.materialList.length ; i++){
          if(i%2 == 0){
            this.materialList[i].icon_type = 1
          }else{
            this.materialList[i].icon_type = 2
          }
        }
      }
    })
  },
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.img-title{
  width:100%;
  position: absolute;
  bottom:0;
  text-align: center;
  text-indent:1em;
  font-size: 0.2rem;
  font-family: '微软雅黑';
}
.gradeNameItem{
  width: 100%;
  height: 50%;
  line-height: 0.4rem;
}
.material-item{
  width: 6.25rem;
  height: 3rem;
  /* background-color: yellow; */
  overflow: hidden;
  position: absolute;
  left:50%;
  top:0;
  margin-left: -3.125rem;
  margin-top:0.225rem;
}
/deep/.vjs-time-divider {
  display: block;
  padding: 0px;
}
/deep/.video-js .vjs-duration,
.vjs-no-flex .vjs-duration {
  display: block;
}
/deep/.video-js .vjs-current-time,
.vjs-no-flex .vjs-current-time {
  display: block;
}
/deep/.vjs-poster {
  background-size: contain;
}
/deep/.vjs-big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -0.75em;
}
.courseTool {
  width: 100%;
  height: 7.7125rem;
  /* background-color: yellow; */
  margin: 0.05rem auto;
  position: relative;
  overflow: auto;
}
.videoBox {
  width: 12.875rem;
  height: 7.7125rem;
  /* background-color: yellow; */
  margin: 0.05rem auto;
  position: relative;
}
.courseVideo {
  width: 14.04rem;
  height: 9rem;
  float: left;
  position: absolute;
  z-index: 3;
  margin-top: 0.8875rem;
  margin-left: 5.25rem;
  background-color: #fff;
  box-shadow: 0px 6px 48px rgba(148, 148, 148, 0.13);
}
.el-menu--horizontal > .is-active.el-menu-item {
  columns: #409eff;
}
.tabs {
  width: 2.425rem;
  max-height: 5.2775rem;
  overflow: auto;
  margin-top: 0.8875rem;
  margin-left: 2.3375rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0.1rem;
  float: left;
  position: absolute;
  box-shadow: 0px 6px 36px rgba(135, 135, 135, 0.2);
  z-index: 3;
}
.tab-item {
  width: 100%;
  height: 1.75rem;
  /* height:50%; */
  /* margin:0 auto; */
  /* display: flex; */
  border-radius: 0.1rem;
  overflow: hidden;
  /* flex-direction:column; */
  /* justify-content: center; */
  color: #43412dff;
  /* align-items: center; */
  cursor: pointer;
}
.gradeName {
  height: 0.8rem;
  /* line-height: 0.8rem; */
  font-size: 0.225rem;
  /* color:#43412DFF; */
}
.iconBox {
  width: 0.45rem;
  height: 0.45rem;
  margin-left: 0.9125rem;
  margin-top: 0.3625rem;
  /* background-color: yellow; */
}
.active {
  background-color: #5b87ff;
  color: #fff;
  box-shadow: 0px 6px 36px rgba(12, 48, 129, 0.23);
}
.el-tabs--border-card /deep/.el-tabs__content{
  padding-top: 0.1rem;
}
.material{
  width: 50%;
  height:50%;
  position: relative;
  float: left;
  /* background-color: blue; */
}
.loadingbox{
	position: absolute;
	background-color: #fff;
	top: -0.5em;
}
.loadingText{
	font-size: 0.75em;
	color: #409EFF;
	text-align: center;
}
.loadingContent{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
